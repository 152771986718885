import React from "react"
import { DiAndroid, DiApple } from "react-icons/di"
import { FaRegHandPointRight } from "react-icons/fa"
import { StaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const getData = graphql`
  {
    info: site {
      siteMetadata {
        android
        ios32
        ios64
      }
    }
  }
`

export default function download() {
  return (
    <StaticQuery
      query={getData}
      render={data => {
        const siteData = data.info.siteMetadata
        const { android, ios32, ios64 } = siteData
        return (
          <div className="container">
            <div className="row pt-5 pb-1">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 appbox py-2 mr-2 mb-3">
                <div className="d-flex pb-2">
                  <div>
                    <FaRegHandPointRight className="icon-test pr-1" />
                  </div>
                  <div>Mega888 Test ID</div>
                </div>
                <div className="text-red text-center">
                  Account: test1 - test10000，Password: Aa1234
                </div>
              </div>
            </div>
            <div className="row pt-2 pb-1">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 appbox py-2 mr-2 mb-3">
                <div className="d-flex pb-2">
                  <div>
                    <DiAndroid className="icon-android pr-1" />
                  </div>
                  <div>APP for Android</div>
                </div>
                <OutboundLink
                  href={android}
                  alt="Mega888 APK Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2">Android</button>
                </OutboundLink>
              </div>
            </div>
            <div className="row pt-2 pb-1">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 appbox py-2 mr-2 mb-3">
                <div className="d-flex pb-2">
                  <div>
                    <DiApple className="icon-ios-64 pr-1" />
                  </div>
                  <div>APP for IOS (Iphone 5s+)</div>
                </div>
                <OutboundLink
                  href={ios64}
                  alt="Mega888 Iphone 5s+ Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2 bg-dark-red">
                    IOS 64Bit
                  </button>
                </OutboundLink>
              </div>
            </div>
            <div className="row pt-2 pb-1">
              <div className="col-lg-3" />
              <div className="col-sm-12 col-lg-6 appbox py-2 mr-2 mb-3">
                <div className="d-flex pb-2">
                  <div>
                    <DiApple className="icon-ios-32 pr-1" />
                  </div>
                  <div>IOS (Iphone 5 or lower)</div>
                </div>
                <OutboundLink
                  href={ios32}
                  alt="Mega888 Iphone 5s Download"
                  className="text-white"
                >
                  <button className="appbox-button py-2 my-2 bg-dark-orange">
                    IOS 32Bit
                  </button>
                </OutboundLink>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
