import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Download from "../components/download"
import ReactGA from "react-ga"

ReactGA.pageview("/index.html")

const IndexPage = () => (
  <Layout>
    <SEO
      title="Mega888"
      keywords={[
        `Mega888 apk`,
        `Mega888 ios`,
        `Mega888 apk free download`,
        `download Mega888 apk`,
        `Mega888 apk download for android mobile`,
        `Mega888 app`,
        `Mega888 apk download`,
        `Mega888 download android`,
        `Mega888 download apk`,
        `Mega888 download`,
        `Mega888 Malaysia`,
      ]}
    />
    <Download />
  </Layout>
)

export default IndexPage
